<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card>
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="newRegister">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="payables"
          :search="search"
        >
          <template v-slot:[`item.installmentNumber`]="{ item }">
            <span>{{ textParcel(item) }}</span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <span>R$ {{ formatPrice(item.amount, "C") }}</span>
          </template>
          <template v-slot:[`item.emissionDate`]="{ item }">
            <span>{{ dateFormat(item.emissionDate) }}</span>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <span>{{ dateFormat(item.dueDate) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="1000px">
        <v-card>
          <v-toolbar>
            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar>Contas a Pagar</v-toolbar>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="5">
                  <v-autocomplete
                    outlined
                    :items="suppliers"
                    v-model="supplierId"
                    label="Fornecedor"
                    item-text="fantasyName"
                    item-value="id"
                    :disabled="disbaledInput"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    outlined
                    v-model="document"
                    label="Documento"
                    :disabled="disbaledInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-autocomplete
                    outlined
                    :items="parents"
                    v-model="parentId"
                    label="Documento Principal"
                    item-value="id"
                    item-text="document"
                    @change="changeInstallment()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    type="date"
                    outlined
                    v-model="emissionDate"
                    label="Data Emissão"
                    :disabled="disbaledInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    type="date"
                    outlined
                    v-model="dueDate"
                    label="Data Vencimento"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                    outlined
                    :items="installments"
                    v-model="installmentNumber"
                    :disabled="disbaledInput"
                    label="Nª Parcela"
                  ></v-select>
                </v-col>
                <v-col cols="2">
                  <v-select
                    outlined
                    :items="installments"
                    v-model="installmentTotal"
                    label="Qtd Parcelas"
                    :disabled="disbaledInput"
                  ></v-select>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    v-model="description"
                    label="Descrição"
                  ></v-text-field>
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    v-money="money"
                    outlined
                    v-model.lazy="amount"
                    label="Valor R$"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                    outlined
                    :items="paymentMethods"
                    v-model="paymentMethod"
                    label="Forma de Pagamento"
                    item-text="label"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog()">
                  Fechar
                </v-btn>
                <v-btn
                  :loading="loadingAction"
                  color="success"
                  @click="registerOrUpdate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import moment from "moment";
export default Vue.extend({
  title: "AccountPayable",
  directives: { money: VMoney },
  data: () => ({
    suppliers: [],
    payables: [],
    paymentMethods: [
      { id: "DINHEIRO", label: "Dinheiro" },
      { id: "DEPOSITO-BANCARIO", label: "Depósito Bancário" },
      { id: "CARTAO-CREDITO", label: "Cartão Crédito" },
    ],
    categories: [],
    members: [],
    parents: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    id: "",
    description: "",
    emissionDate: null,
    dueDate: null,
    amount: "",
    installmentNumber: 1,
    installmentTotal: 1,
    document: "",
    parentId: null,
    supplierId: null,
    paymentMethod: "",
    memberId: null,
    description: "",
    type: null,
    openDialog: false,
    disbaledInput: false,
    installments: [],
    headers: [
      {
        text: "Data Emissão",
        align: "start",
        value: "emissionDate",
        filtering: true,
      },
      {
        text: "Parcela",
        align: "start",
        value: "installmentNumber",
        filtering: true,
      },
      {
        text: "Fornecedor",
        align: "start",
        value: "supplier.fantasyName",
        filtering: true,
      },
      {
        text: "Vencimento",
        align: "start",
        value: "dueDate",
        filtering: true,
      },
      {
        text: "Valor R$",
        align: "start",
        value: "amount",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    newRegister() {
      this.document = moment().unix();
      this.openDialog = true;
    },
    changeInstallment() {
      http
        .get(
          `finance/account-payables?filter=id||eq||${this.parentId}&or=parentId||eq||${this.parentId}&sort=installmentNumber,DESC`
        )
        .then((d) => {
          if (d.data[0].installmentNumber + 1 > d.data[0].installmentTotal) {
            this.snackbar.color = "orange";
            this.snackbar.text =
              "Este documento já atingiu o máximo de parcelas. Altere o documento principal para continuar.";
            this.snackbar.opened = true;
          } else {
            this.installmentNumber = d.data[0].installmentNumber + 1;
            this.installmentTotal = d.data[0].installmentTotal;
            this.supplierId = d.data[0].supplierId;
            this.description = d.data[0].description;
            this.amount = d.data[0].amount;
            this.emissionDate = d.data[0].emissionDate;
            this.paymentMethod = d.data[0].paymentMethod;
            this.document =
              d.data[d.data.length - 1].document + "/" + this.installmentNumber;
            this.dueDate = moment(d.data[0].dueDate)
              .add(30, "days")
              .format("YYYY-MM-DD");
            this.disbaledInput = true;
          }
        });
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return type === "C"
        ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : "-" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    textParcel(item) {
      return `${item.installmentNumber}/${item.installmentTotal}`;
    },
    factoryInstallments() {
      let end = 360;
      for (let i = 1; i <= end; i++) {
        this.installments.push(i);
      }
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getItems() {
      this.loading = true;
      http.get("finance/account-payables").then((data) => {
        this.payables = data.data;
        this.loading = false;
      });
    },
    getParents() {
      this.loading = true;
      http
        .get("finance/account-payables?filter=parentId||isnull||true")
        .then((data) => {
          this.parents = data.data;
          this.loading = false;
        });
    },

    getSuppliers() {
      this.loading = true;
      http.get("suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
    },

    registerOrUpdate() {
      this.loadingAction = true;
      let amount = this.amount.toString().replace("R$ ", "");
      amount = amount.replace(".", "");
      amount = amount.replace(",", ".") * 1;
      const payload = {
        emissionDate: this.emissionDate,
        dueDate: this.dueDate,
        description: this.description,
        document: this.document,
        parentId: this.parentId,
        installmentNumber: this.installmentNumber,
        installmentTotal: this.installmentTotal,
        paymentMethod: this.paymentMethod,
        supplierId: this.supplierId,
        congregationId: localStorage.getItem("congregationId"),
        amount,
      };
      if (this.id) {
        http.put(`finance/account-payables/${this.id}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("finance/account-payables", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
            this.getItems();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      location.reload();
    },
    editItem(item) {
      this.id = item.id;
      this.amount = item.amount;
      this.installmentNumber = item.installmentNumber;
      this.installmentTotal = item.installmentTotal;
      this.paymentMethod = item.paymentMethod;
      this.emissionDate = item.emissionDate;
      this.supplierId = item.supplierId;
      this.description = item.description;
      this.dueDate = item.dueDate;
      this.document = item.document;
      this.parentId = item.parentId;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
    this.getSuppliers();
    this.factoryInstallments();
    this.getParents();
  },
});
</script>